section.contacts {
  max-width: 30rem;
  margin: 0 auto;
}

section.contacts ul.links {
  list-style-type: none;
}

section.contacts ul.links svg {
  fill: #332148;
  opacity: .5;
  border-radius: 100rem;
  width: 5rem;
  margin: .25rem;
  transition: opacity .15s linear;
}

section.contacts ul.links svg:hover {
  opacity: 1;
}

@media screen and (width <= 400px) {
  section.contacts ul.links svg {
    width: 2rem;
  }
}

@media screen and (width >= 400px) and (width <= 1000px) {
  section.contacts ul.links svg {
    width: 3rem;
  }
}
/*# sourceMappingURL=index.c1480b9c.css.map */
